import React, { Component } from "react";
import PropTypes from "prop-types";
import DeckGL, {
  GeoJsonLayer,
  MapController,
  FlyToInterpolator,
  IconLayer
} from "deck.gl";
import MapGL, { Marker } from "react-map-gl";
import bbox from "@turf/bbox";
import pointInPolygon from "@turf/boolean-point-in-polygon";
import { point } from "@turf/helpers";
import { featureEach } from "@turf/meta";
import WebMercatorViewport from "viewport-mercator-project";
import { easeQuad } from "d3-ease";
import styled, { keyframes } from "styled-components";

import { getFieldColor } from "../utils";
import pin from "../../images/icons/pin.svg";
import Popup from "../Popup";
import media from "../../styles/media";
import DynamicPin from "../DynamicPin";
import {
  defaultMapStyle,
  getDataLayer,
  selectedRegionLayer,
  outlineLayer
} from "../../map-style";

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  ${media.up.md`
    flex-direction: row;
    `}
`;

const MapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

// const animation = keyframes`
// 0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// `;

// const Spinner = styled.div`
//   display: inline-block;
//   width: 64px;
//   height: 64px;
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   :after {
//     content: " ";
//     display: block;
//     width: 46px;
//     height: 46px;
//     margin: 1px;
//     border-radius: 50%;
//     border: 5px solid ${props => props.theme.colors.primary};
//     border-color: ${props => props.theme.colors.primary} transparent
//       ${props => props.theme.colors.primary} transparent;
//     animation: ${animation} 1.2s linear infinite;
//   }
// `;

// const percentColors = [
//   { pct: 0.0, color: { r: 233, g: 99, b: 10 } },
//   { pct: 0.5, color: { r: 246, g: 201, b: 0 } },
//   { pct: 1.0, color: { r: 148, g: 221, b: 128 } }
// ];

// const getColorForPercentage = function(pct) {
//   for (var i = 1; i < percentColors.length - 1; i++) {
//     if (pct < percentColors[i].pct) {
//       break;
//     }
//   }
//   const lower = percentColors[i - 1];
//   const upper = percentColors[i];
//   const range = upper.pct - lower.pct;
//   const rangePct = (pct - lower.pct) / range;
//   const pctLower = 1 - rangePct;
//   const pctUpper = rangePct;
//   const color = {
//     r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
//     g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
//     b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
//   };
//   return [color.r, color.g, color.b, 255];
//   // or output as hex if preferred
// };

export default class Map extends Component {
  static propTypes = {
    prop: PropTypes
  };

  state = {
    geojson: null,
    geojsonLayer: null,
    viewport: null,
    selectedLayer: null,
    addrressLoaction: null,
    loadingMap: true,
    loadingGeoJson: true,
    fileDownloadPercentage: 0,
    fileDonwloadComplete: false,
    onHoverRegion: null,
    popupLocation: []
  };

  mapRef = null;
  deckRef = null;

  containerRef = React.createRef();

  constructor(props) {
    super(props);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.handleDetailsClose = this.handleDetailsClose.bind(this);
    this.setCoordinates = this.setCoordinates.bind(this);
  }

  async componentDidMount() {
    console.log(this.props.range);
    this.props.geoJson.features.map(f => {
      f.properties.fieldColor = getFieldColor(
        f.properties[this.props.geoJson.properties.value_name],
        this.props.range
      );
      f.properties.Streitinte = parseFloat(f.properties.Streitinte);
      return f;
    });

    const mapStyle = defaultMapStyle;
    mapStyle.sources = {
      ...mapStyle.sources,
      regions: { type: "geojson", data: this.props.geoJson },
      selectedRegion: { type: "geojson", data: null }
    };

    mapStyle.layers = [
      ...mapStyle.layers,
      getDataLayer(this.props.range),
      outlineLayer,
      selectedRegionLayer
    ];

    this.setState({ mapStyle });

    window.addEventListener("resize", this.updateDimensions);
    this.setState({
      ...this.state,
      loadingMap: false,
      viewport: new WebMercatorViewport({
        width: this.containerRef.current
          ? this.containerRef.current.clientWidth
          : 1000,
        height: this.containerRef.current
          ? this.containerRef.current.clientHeight !== 0
            ? this.containerRef.current.clientHeight
            : 1000
          : 1000
      }).fitBounds(
        [[5.98865807458, 47.3024876979], [15.0169958839, 54.983104153]],
        {
          padding: 0,
          offset: [-50, -150]
        }
      )
    });
  }

  componentDidUpdate(prevProps) {
    const newStyle = { ...this.state.mapStyle };
    if (prevProps.selectedRegionId !== this.props.selectedRegionId) {
      if (this.props.selectedRegionId === null) {
        this.handleDetailsClose();
      } else {
        const _this = this;
        let feature = null;
        featureEach(newStyle.sources.regions.data, function(
          currentFeature,
          featureIndex
        ) {
          if (currentFeature.properties.rs === _this.props.selectedRegionId) {
            feature = currentFeature;
            newStyle.sources.selectedRegion.data = feature;
          }
        });

        this.setState({
          ...this.state,
          mapStyle: { ...this.state.mapStyle },
          viewport: this.getBoundingBoxViewport(feature.geometry)
        });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  getBoundingBoxViewport(geometry) {
    const box = bbox(geometry);
    const v = new WebMercatorViewport({
      width: this.containerRef.current.clientWidth,
      height: this.containerRef.current.clientHeight
    }).fitBounds([[box[0], box[1]], [box[2], box[3]]], {
      padding: 10,
      offset: [0, -100]
    });
    return {
      ...v,
      zoom: 7,
      transitionDuration: 1000,
      transitionInterpolator: new FlyToInterpolator(),
      transitionEasing: easeQuad
    };
  }

  setDefaultViewport = () => {
    const { width, height, ...rest } = this.state.viewport;
    this.setState({
      ...this.state,
      viewport: {
        ...rest,
        width: this.containerRef.current
          ? this.containerRef.current.clientWidth
          : 100,
        height: this.containerRef.current
          ? this.containerRef.current.clientHeight
          : 100
      }
    });
  };

  handleViewportChange = viewport => {
    this.setState({
      ...this.state,
      viewport: { ...viewport }
    });
  };

  updateDimensions() {
    this.setState({
      ...this.state,
      viewport: {
        ...this.state.viewport,
        width: this.containerRef.current.clientWidth,
        height: this.containerRef.current.clientHeight
      }
    });
  }

  handleDetailsClose() {
    const mapStyle = { ...this.state.mapStyle };
    featureEach(mapStyle.sources.regions.data, function(
      currentFeature,
      featureIndex
    ) {
      currentFeature.properties.selected = false;
    });
    mapStyle.sources.selectedRegion.data = {
      type: "FeatureCollection",
      features: []
    };
    // debugger;

    this.setState(prevState => ({
      ...prevState,
      mapStyle,
      viewport: {
        ...new WebMercatorViewport({
          width: this.containerRef.current.clientWidth,
          height: this.containerRef.current.clientHeight
        }).fitBounds(
          [[5.98865807458, 47.3024876979], [15.0169958839, 54.983104153]],
          {
            padding: 0,
            offset: [-50, -150]
          }
        ),
        transitionDuration: 1000,
        transitionInterpolator: new FlyToInterpolator(),
        transitionEasing: easeQuad
      }
    }));
  }

  setCoordinates(e) {
    this.setState({
      ...this.state,
      x: e.clientX,
      y: e.clientY
    });
  }

  onChange() {
    if (this.props && typeof this.props.onUserInteraction === "function") {
      this.props.onUserInteraction();
    }
  }

  onMapHover = event => {
    const {
      features,
      srcEvent: { offsetX, offsetY }
    } = event;
    const hoveredRegion = features && features.find(f => f.layer.id === "data");

    this.setState({ hoveredRegion, popupLocation: [offsetX, offsetY] });
  };

  onMapClick = event => {
    const { features } = event;
    const { mapStyle } = this.state;
    const clickedFeature =
      features && features.find(f => f.layer.id === "data");
    if (!clickedFeature) {
      featureEach(mapStyle.sources.regions.data, function(
        currentFeature,
        featureIndex
      ) {
        currentFeature.properties.selected = false;
      });
    } else {
      if (typeof this.props.onSelectRegion === "function") {
        this.props.onSelectRegion(clickedFeature.properties);
      }
    }
  };

  onMouseLeave = e => {
    this.setState({ hoveredRegion: null });
  };

  onMouseOver = e => {
    this.setState({ hoveredRegion: null });
    console.log("test");
  };

  render() {
    const { viewport, mapStyle, hoveredRegion, popupLocation } = this.state;
    const {
      pinCoordinates,
      geoJson,
      selectedRegionId,
      selectedRegion
    } = this.props;
    const ICON_MAPPING = {
      marker: { x: 0, y: 0, width: 53, height: 75, anchorY: 75 }
    };
    const iconLayer = new IconLayer({
      id: "icon-layer",
      data: [1],
      pickable: true,
      // iconAtlas and iconMapping are required
      // getIcon: return a string
      iconAtlas: pin,
      iconMapping: ICON_MAPPING,
      getIcon: d => "marker",

      sizeScale: 30,
      getPosition: d => [pinCoordinates.longitude, pinCoordinates.latitude],
      getSize: d => 5,
      getColor: d => [0, 140, 0]
    });

    return (
      <Container onMouseLeave={this.onMouseLeave}>
        {hoveredRegion && (
          <div
            onMouseOver={this.onMouseOver}
            style={{
              position: "absolute",
              left: popupLocation[0] + 150,
              top: popupLocation[1] - 200,
              zIndex: 999
            }}
          >
            <Popup
              data={hoveredRegion.properties}
              region={hoveredRegion.properties.GEN}
              color={
                hoveredRegion.properties
                  ? hoveredRegion.properties.fieldColor
                  : null
              }
              info={geoJson.properties.popup_sections}
              infoText="Klicken Sie, um mehr zu erfahren"
            ></Popup>
          </div>
        )}
        <MapContainer ref={this.containerRef}>
          <MapGL
            {...viewport}
            width="100%"
            height="100%"
            mapStyle={mapStyle}
            maxZoom={7}
            onViewportChange={this.handleViewportChange}
            mapboxApiAccessToken={
              "pk.eyJ1IjoiY3B0Y29tcGlsZXIiLCJhIjoiY2pydXRzNDUwMGNxdzQ3bm1hZjJhejM4aSJ9.w9lv0mKtJQFjuyou42q2tw"
            }
            onHover={this.onMapHover}
            onClick={this.onMapClick}
            ref={ref => {
              if (ref && !this.mapRef) {
                this.mapRef = { current: ref };
                this.props.passRef(this.mapRef);
              }
            }}
          >
            {selectedRegionId && pinCoordinates && (
              <Marker
                latitude={pinCoordinates.latitude}
                longitude={pinCoordinates.longitude}
                offsetLeft={-32}
                offsetTop={-87}
              >
                <DynamicPin color={selectedRegion.fieldColor}></DynamicPin>
              </Marker>
            )}
          </MapGL>
        </MapContainer>
      </Container>
    );
  }
}
